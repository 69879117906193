import {LitElement, html, css} from 'lit';

class DeleteLastAssignment  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: inline;
      }
      dw-icon {
        cursor: pointer;
        position: relative;
        top: 4px;
        left: 10px;
        --dw-icon-size: 20px;
        --dw-icon-color: var(--primary-color);
      }
      .hide {
        display: none;
      }
    `;
  }

  static get properties() {
    return {
      studentId: { type: String },
    };
  }

  constructor() {
    super();
  }

  render() {
    return html`
      <dw-icon icon="delete" @click="${this.delete}"></dw-icon>
      <form method="POST" class="hide" action="/alumnos/delete-last-assignment">
        <input type="hidden" value="${this.studentId}" name="studentId">
      </form>
    `;
  }

  delete() {
    this.shadowRoot.querySelector('form').submit();
  }
}

customElements.define('delete-last-assignment', DeleteLastAssignment);