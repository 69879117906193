import {LitElement, html, css} from 'lit';
import { DileSlideDown } from '@dile/ui/mixins/slide-down';

class DwSlide extends DileSlideDown(LitElement) {
  static get properties() {
    return {
      opened: { type: Boolean },
    }
  }
  constructor() {
    super();
    this.opened = false;
  }
  static get styles() {
    return css`
    :host {
      display: block;
    }
    div {
      cursor: pointer;
      display: flex;
      align-items: flex-start;
      border-bottom: 1px solid #ddd;
      margin-bottom: 10px;
    }
    .title {
      flex-grow: 1;
    }
    dw-icon {
      --dw-icon-size: 36px;
      --dw-icon-color: #aaa;
      position: relative;
      transform: rotate(-90deg);
    }
    .iconopen {
      transform: rotate(0deg);
    }
    #content {
      overflow: hidden;
      transition: height 0.3s ease-in;
      -webkit-transition: height 0.3s ease-in;
    }
    .closed {
      height: 0px;
    }
    `;
  }
  render() {
    return html`
    <div @click=${this.toggle}>
      <span class="title"><slot name="title"></slot></span>
      <span class="icon"><dw-icon icon="arrow_drop_down" class="${this.opened ? 'iconopen' : ''}"></dw-icon></span>
    </div>
    <section id="content" class="${this.opened ? '' : 'closed'}">
      <slot name="content"></slot>
    </section> 
    `;
  }

  firstUpdated() {
    this.content = this.shadowRoot.getElementById('content');
    //this.finalHeight = this.content.
  }

  toggle() {
    // console.log('dw-slide toggle')
    this.opened = !this.opened;
    if(this.opened) {
      this.slideShow(this.content);
    } else {
      this.slideHide(this.content);
      this.dispatchEvent(new CustomEvent('dw-slide-hide', {
        bubbles: true,
        composed: true,
      }));
    }
  }
}
customElements.define('dw-slide', DwSlide);