import {
  SEND_FEEDBACK,
  SEND__MODAL_FEEDBACK,
  CHANGE_LOADING
} from '../actions/app_actions';

const INITIAL_STATE = {
  feedback: null,
  loading: false,
  modalFeedback: null,
}

export const app = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SEND_FEEDBACK:
      return {
        ...state,
        feedback: action.feedback
      };
    case SEND__MODAL_FEEDBACK:
      return {
        ...state,
        modalFeedback: action.feedback
      };
    case CHANGE_LOADING:
      return {
        ...state,
        loading: action.loading
      };
    default:
      return state;
  }
}