import { css } from 'lit';

export const interventionListStyles = css`
  :host {
    border-bottom: 1px solid #ddd;
  }
  .listitem {
    margin: 0;
    padding: 4px;
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    --dw-icon-color: #303030;
    --dw-icon-size: 18px;
  }
  .listcomments {
    margin: 0.2rem;
    padding: 0.2rem;
    font-size: 0.9rem;
    opacity: 0.6;
  }
  dw-delete {
    position: relative;
    top: 3px;
  }
  intervention-list-individual-item:nth-child(even), intervention-list-joint-item:nth-child(even) { background-color: #f5f5f5; }
`;
