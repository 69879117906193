import { css } from 'lit';

export const userOptionsStyle = css`
  article {
    --dw-icon-color: var(--primary-color);
    --dw-icon-size: 24px;
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    justify-content: center;
  }
  article dw-icon {
    position: relative;
    top: 3px;
  }
  article a {
    flex-flow: 1;
    margin-left: 8px;
    color: var(--primary-color);
  }
`;