  import {LitElement, html, css} from 'lit';

export class DwButton extends LitElement {
  static get styles() {
    return css`
    :host {
      display: inline-block;
      --dw-icon-size: var(--dw-button-icon-size, 1.2em);
      --dw-icon-color: var(--dw-button-icon-color, var(--primary-color));
    }
    dw-icon {
      position: relative;
      top: 3px;
      margin-right: 4px;
    }
    span {
      cursor: pointer;
      display: inline-block;
      border-radius: var(--dw-button-border-radius, 1.3em);
      padding-top: var(--dw-button-padding-y, 7px);
      padding-bottom: var(--dw-button-padding-y, 7px);
      padding-left: var(--dw-button-padding-x, 1em);
      padding-right: var(--dw-button-padding-x, 1em);
      transition: all 0.2s ease-in;
      -webkit-transition: all 0.2s ease-in;
      background-color: var(--dw-button-background-color, #fff);
      color: var(--dw-button-text-color, var(--primary-color));
      font-size: var(--dw-button-font-size, 0.9em);
      border: var(--dw-button-border, none);
      line-height: 1.5em;
    }
    span:hover {
      background-color: var(--dw-button-hover-background-color, #fff);
      color: var(--dw-button-hover-text-color, var(--primary-color));
      --dw-icon-color: var(--dw-button-hover-text-color);
    }
    span.disabled:hover {
      background-color: var(--dw-button-background-color, #303030);
      color: var(--dw-button-text-color, #fff);
      --dw-icon-color: var(--dw-button-hover-text-color);
    }
    .disabled {
      opacity: 0.5;
    }
    `;
  }

  static get properties() {
    return {
      icon: { type: String },
      disabled: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.icon = '';
  }

  render() {
    return html`
    <span class="${this.disabled ? 'disabled' : '' }" @click=${this.doClick}>
      ${this.icon
        ? html`<dw-icon icon="${this.icon}"></dw-icon>`
        : ''
      }
      <slot></slot>
    </span>
    `;
  }

  doClick(e) {
    if(this.disabled) {
      e.stopPropagation();
      this.dispatchEvent(new CustomEvent('dw-button-clicked-but-disabled', {
        bubbles: true,
        composed: true,
      }));
    }
  }
}
customElements.define('dw-button', DwButton);