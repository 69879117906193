import {LitElement, html, css} from 'lit';

class DocenteParaAsignar  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
        color: var(--primary-color);
        cursor: pointer;
      }
    `;
  }

  static get properties() {
    return {
      ruta: { type: String },
      name: { type: String },
    };
  }

  constructor() {
    super();
    this.addEventListener('click', () => {
      this.dispatchEvent(new CustomEvent('asignation-docente', {
        bubbles: true,
        composed: true,
        detail: {
          ruta: this.ruta,
          name: this.name,          
        }
      }));
    });
  }

  render() {
    return html`
      <slot></slot>
    `;
  }


}

customElements.define('docente-para-asignar', DocenteParaAsignar);