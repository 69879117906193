import {LitElement, html, css} from 'lit';
import '@dile/ui/components/tabs/tabs';

class InterventionFiltersSelect  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
        --dile-tab-selected-background-color: transparent;
        --dile-tab-background-color: transparent;
        --dile-tab-selected-text-color: var(--primary-color, blue);
        --dile-tab-selected-line-color: var(--accent-color, blue);
      }
      dile-tabs {
        margin-bottom: 15px;
      }
    `;
  }

  static get properties() {
    return {
      interventionType: { type: String },
    };
  }

  constructor() {
    super();
    this.interventionType = 'individual';
  }

  render() {
    return html`
      <dile-tabs selected="${this.interventionType}" attrForSelected="name" @dile-selected-changed="${this.typeChanged}">
        <dile-tab name="individual"><span class="hidemobile">Intervención</span> individual</dile-tab>
        <dile-tab name="conjunta"><span class="hidemobile">Intervención</span> conjunta</dile-tab>
      </dile-tabs>
      ${this.interventionType == 'individual'
      ? html`<slot name="indivualselect"></slot>`
      : html`<slot name="jointselect"></slot>`
      }
    `;
  }

  typeChanged(e) {
    this.interventionType = e.detail.selected;
    this.querySelector('#intervention_group').value = e.detail.selected;
  }
}

customElements.define('intervention-filters-select', InterventionFiltersSelect);