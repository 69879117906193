import {LitElement, html, css} from 'lit';
import '../interface/eit-badge';

import { store } from '../../redux/store';
import { sendModalFeedback } from '../../redux/actions/app_actions';

class ReportedMessageIntervention  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
      }
    `;
  }

  static get properties() {
    return {
      data: { type: Object }
    };
  }

  constructor() {
    super();
    this.data = {
      interventions: '',
      comments: []
    }
  }

  render() {
    return html`
      ${this.data.comments.length == 0
        ? html`${this.data.interventions}`
        : html`
          <eit-badge status="neutral" value="" @badge-click="${this.showComments}">
            ${this.data.interventions}
          </eit-badge>`
      }
    `;
  }

  showComments() {
    console.log(this.data.comments);
    if(this.data.comments.length > 0) {
      let message = '<ul>';
      for (let comment of this.data.comments) {
        message += `<li>(${comment.name}): <br>${comment.comments}</li>`;
      }
      message += '</ul>';
      store.dispatch(sendModalFeedback(message));
    }
  }
}

customElements.define('reported-message-intervention', ReportedMessageIntervention);