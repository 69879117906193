import {LitElement, html, css} from 'lit';
import { DwButton } from './dw-button';

class RoundedButton  extends DwButton {

  static get styles() {
    return [super.styles, css`
    :host{
      --dw-button-background-color: var(--primary-color, #888);
      --dw-button-hover-background-color: #ddd;
      --dw-button-text-color: #fff;
      --dw-button-hover-text-color: #888;
    }
    `];
  }

  render() {
    return html`
      <dw-button><slot></slot></dw-button>
    `;
  }
}

customElements.define('rounded-button', RoundedButton);