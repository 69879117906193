import {LitElement, html, css} from 'lit';

class MenuResponsive  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
        --dw-menu-overlay-width: 220px;
        --dw-icon-color: var(--primary-color);
      }
      .menubutton {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: var(--primary-color);
        border-radius: 0.7rem;
        padding: 10px 10px;
        box-sizing: border-box;
        border-radius: 5px;
        box-shadow: 0 1px 4px rgba(0,0,0,0.1) , 0 2px 3px rgba(0,0,0,.1);
      }
      span {
        display: inline-block;
        margin-right: 10px;
      }
    `;
  }

  static get properties() {
    return {};
  }

  constructor() {
    super();
  }

  render() {
    return html`
      <dw-menu-overlay horizontalAlign="center" moveLeft="-60">
        <div class="menubutton" slot="trigger">
          <span>Menú</span>
          <dw-icon icon="more_vert"></dw-icon>
        </div>
        <div slot="content">
          <slot></slot>
        </div>
      </dw-menu-overlay>
    `;
  }
}

customElements.define('menu-responsive', MenuResponsive);