import {LitElement, html, css} from 'lit';
import './intervention-form';

class InterventionCreate  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
      }
      section {
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 10px;
      }
      .form {
        transition: 0.3s ease-in;
        transition-property: border-color, border-width;
      }
      .highlight {
        border-color: var(--primary-color, #38c172);
        border-width: 4px;
      }
      ul {
        padding: 10px;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
      li {
        margin: 0;
        padding: 0;
        text-transform: uppercase;
        list-style-type: none;
      }
    `;
  }

  static get properties() {
    return {
      extraFormClass: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.extraFormClass = '';
  }

  firstUpdated() {
    this.createform = this.shadowRoot.getElementById('createform');
  }

  render() {
    return html`
    <section>
      <div class="form ${this.extraFormClass}">
        <intervention-form action="Crear"></intervention-form>
      </div>
    </section>
    `;
  }

  flash() {
    this.extraFormClass = 'highlight';
    setTimeout(() => {
      this.extraFormClass = '';
    }, 300);
  }
}

customElements.define('intervention-create', InterventionCreate);