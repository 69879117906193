import {
  positiveFeedback,
  negativeFeedback
} from './app_actions';

export const LOG_USER = 'LOG_USER';
export const DELETE_NOTIFICATIONS = 'DELETE_NOTIFICATIONS';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';

export const logUser = (user) => {
  return {
    type: LOG_USER,
    user
  }
}

export const getUser = () => (dispatch) => {
  axios.get('/api/get_user') 
  .then(res => {
    if(res.status == 200) {
      if(res.data && res.data.data.loggedIn) {
        //console.log(res.data.data.user)
        dispatch(logUser(res.data.data.user));
      }
    }
  })
}

export const checkNotificationsAsRead = () => (dispatch) => {
  // console.log('checkNotificationsAsRead');
  axios.get('/api/set_notifications_to_readed')
  .then(() => {
    setTimeout( () => {
      dispatch(getUser())
    }, 5000)
  });
}

export const deleteNotifications = () => (dispatch) => {
  // console.log('deleteNotifications');
  axios.get('/api/delete_notifications')
  .then((res) => {
    if(res.status == 200 && res.data && !res.data.error) {
      dispatch(positiveFeedback('Notificaciones borradas'));
      dispatch(getUser());
    } else {
      throw true;
    }  
  })
  .catch(err => dispatch(negativeFeedback('No se pudo borrar las notificaciones')));
}


export const addNotificationRecived = (notification) => {
  return {
    type: ADD_NOTIFICATION,
    notification
  }
}