import {LitElement, html, css} from 'lit';
import { iconStyles } from '../styles/icon-styles';
import { icons } from '../icons';

export const IconsMixin = (superClass) => class extends superClass {
  
  static get styles() {
    return iconStyles;
  }

  render() {
    return html`${icons[this.icon]}`;
  }

  static get properties() {
    return { 
      icon: { type: String }
    };
  }

}