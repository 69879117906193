import {LitElement, html, css} from 'lit';
import { store } from '../../redux/store';
import { positiveFeedback, negativeFeedback } from '../../redux/actions/app_actions';

import './lock-display-year';

class LockManager  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
      }
    `;
  }

  static get properties() {
    return {
      years: { type: Array }
    };
  }

  constructor() {
    super();
    this.years = [];
  }

  firstUpdated() {
    this.ajaxCreate = this.shadowRoot.getElementById('createLockAjax');
    this.ajaxGet = this.shadowRoot.getElementById('getLockAjax');
    this.ajaxGet.generateRequest();
  }

  render() {
    return html`
      <dw-ajax
        id="getLockAjax"
        url="/api/lock/get-locks"
        method="get"
        @ajax-success="${this.getLockSuccess}"
        @ajax-error="${this.ajaxLockError}"
      ></dw-ajax>
      <dw-ajax
        id="createLockAjax"
        url="/api/lock/create-lock"
        method="post"
        @ajax-success="${this.createLockSuccess}"
        @ajax-error="${this.ajaxLockError}"
      ></dw-ajax>
      <lock-form @do-lock="${this.doLock}"></lock-form>
      ${this.years.map( year => html`<lock-display-year .year="${year}"></lock-display-year>`)}
      
    `;
  }

  doLock(e) {
    console.log('doLock', e.detail);
    this.ajaxCreate.data = e.detail;
    this.ajaxCreate.generateRequest();
  }

  ajaxLockError(e) {
    store.dispatch(negativeFeedback(e.detail));
  }

  createLockSuccess(e) {
    store.dispatch(positiveFeedback(e.detail));
    this.ajaxGet.generateRequest();    
  }

  getLockSuccess(e) {
    console.log('locksuccess get', e.detail);
    this.years = e.detail;
  }


}

customElements.define('lock-manager', LockManager);