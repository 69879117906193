import {LitElement, html, css} from 'lit';
import './intervention-list-invividual';
import './intervention-list-joint';

class InterventionList  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
      }
      h2 {
        margin-bottom: 10px;
      }
    `;
  }

  static get properties() {
    return {
      interventions: { type: Object },
      superadmin: { type: Boolean },
      hasLock: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.interventions = {};
  }

  render() {
    return html`
      ${this.interventions && this.interventions.individual && this.interventions.individual.length > 0
        ? html`<h2>Intervenciones individuales</h2>
                <intervention-list-invividual ?superadmin="${this.superadmin}" ?hasLock="${this.hasLock}" .items="${this.interventions.individual}"></intervention-list-invividual>`
        : ''
      }
      
      ${this.interventions && this.interventions.joint && this.interventions.joint.length > 0
        ? html`<h2>Intervenciones conjuntas</h2>
                <intervention-list-joint ?superadmin="${this.superadmin}" ?hasLock="${this.hasLock}" .items="${this.interventions.joint}"></intervention-list-joint>`
        : ''
      }
    `;
  }
}

customElements.define('intervention-list', InterventionList);