import {LitElement, html, css} from 'lit';
import '@dile/ui/components/toast/toast';

import { connect } from 'pwa-helpers/connect-mixin.js';
import { store } from '../../redux/store.js';

class DwFeedback extends connect(store)(LitElement) {
  static get properties() {
    return {
      time: { type: Number },
      incomingMsg: { type: Object },
      initMsg: { type: String },
      initStatus: { type: String }
    }
  }

  static get styles() {
    return css`
      :host {
        display: block;
        --dile-toast-padding: 25px;
        --dile-toast-font-size: 1.2em;
      }
      @media(min-width: 500px) {
        dile-toast {
          --dile-toast-width: 450px;
        }
      }
    `;
  }

  constructor() {
    super();
    this.time = 5000;
    this.initStatus = 'neutral'
  }

  render() {
    return html`      
      <dile-toast id="toast" duration=${this.time}></dile-toast>
    `;
  }

  stateChanged(state) {
    this.incomingMsg = state.app.feedback; 
    //feedconsole.log(state);
  }

  changeIncomingMsg(feedbackMsg) {
    if(feedbackMsg && feedbackMsg.msg && feedbackMsg.status) {
      this.toast.open(feedbackMsg.msg, feedbackMsg.status);
    }
  }

  firstUpdated() {
    this.toast = this.shadowRoot.getElementById('toast');
    if(this.initMsg) {
      this.changeIncomingMsg({
        msg: this.initMsg, 
        status: this.initStatus
      });
    }
  }

  updated(changedProperties) {
    if(changedProperties.has('incomingMsg')) {
      this.changeIncomingMsg(this.incomingMsg);
    }
  }
}

customElements.define('dw-feedback', DwFeedback);