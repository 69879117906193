import {LitElement, html, css} from 'lit';
import { interventionListStyles } from '../../styles/intervention-list-styles';
import './intervention-list-joint-item';

class InterventionListJoint  extends LitElement {

  static get styles() {
    return [interventionListStyles, css`
      :host {
        display: block;
      }
    `];
  }

  static get properties() {
    return {
      items: { type: Array },
      superadmin: { type: Boolean },
      hasLock: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.items = [];
  }

  render() {
    return html`
      <div>
        ${this.items.map( (item) => html`
          <intervention-list-joint-item ?superadmin="${this.superadmin}" ?hasLock="${this.hasLock}" .item="${item}"></intervention-list-joint-item>
        `
        )}
      </div>
    `;
  }

}

customElements.define('intervention-list-joint', InterventionListJoint);