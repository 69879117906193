import { css } from 'lit';

export const formsStyles = css`
  :host[hidden] { display: none;}
  .cancelbutton {
    display: inline-block;
    --dw-button-background-color: #888;
  }
  section{
    flex-grow: 1;
  }
  div {
    display: flex;
    flex-direction: column;
  }
  .actions {
    margin-top: 10px;
    padding-bottom: 5px;
  }
  
`;
