import {LitElement, html, css} from 'lit';

class DwActionController extends LitElement {
  static get properties() {
    return {
      responsive: { type: Boolean }
    };
  }
  static get styles() {
    return css`
    :host {
      display: block; 
      --dw-icon-size: 2em;
    }

    section {
      background-color: var(--dw-action-controller-background-color, transparent);
      width: 100%;
      border: 1px solid #f9f9f9;
      display: flex;
      align-items: center;
      padding: 0.75rem 1rem;
      box-sizing: border-box;
      margin-bottom: 20px;
      border-radius: 5px;
      box-shadow: var(--dw-action-controller-shadow, 0 1px 4px rgba(0,0,0,0.1) , 0 2px 3px rgba(0,0,0,.1));
    }
    .actions {
      flex-grow: 1;
      display: flex;
      align-items: center;
    }
    
    @media(max-width: 510px) {
      section.responsive {
        flex-direction: column;
      }
    }
    `;
  }
  render() {
    return html`
      <section class="${this.responsive ? 'responsive' : ''}">
        <div class="actions">
          <slot></slot>
        </div>
        <div class="right">
          <slot name="right"></slot>
        </div>
      </section>
    `;
  }
}
customElements.define('dw-action-controller', DwActionController);