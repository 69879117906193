import {LitElement, html, css} from 'lit';
import { store } from '../../redux/store';
import { neutralFeedback } from '../../redux/actions/app_actions';

class NuevaAsignacionDocente  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
      }
      .listado {
        padding: 15px;
      }
      h1 {
        margin: 0px 0 10px;
        font-size: 0.8rem;
        font-weight: bold;
      }
      dile-confirm {
        --dile-confirm-cancel-button-color: #666;
        --dile-confirm-text-transform: none;
        --dile-confirm-cancel-text-button-color: #fff;
        --dile-modal-content-shadow-blur: 6px;
        --dile-modal-content-shadow-color: rgba(0,0,0,0.5);
        --dile-modal-content-shadow-displacement: 2px;
      }
    `;
  }

  static get properties() {
    return {
      asignData: { type: Object }
    };
  }
  constructor() {
    super();
    this.asignData = {};
    this.addEventListener('asignation-docente', (e) => {
      this.shadowRoot.querySelector('dile-confirm').open();
      this.shadowRoot.querySelector('dw-menu-overlay').close();
      this.asignData = e.detail;
    })
  }

  render() {
    return html`
      <dile-confirm
        @dile-confirm-cancelled="${this.cancelled}"
        @dile-confirm-accepted="${this.acceted}"
        cancelLabel="Cancelar"
        acceptLabel="Aceptar"
      >
        ¿Asignar a ${this.asignData.name} como docente?
      </dile-confirm>
      <dw-menu-overlay horizontalAlign="left">
        <div slot="trigger"><slot name="trigger"></slot></div>
        <div class="listado" slot="content" @click="${this.doclick}">
          <h1>Educadores disponibles en distrito:</h1>
          <slot name="content"></slot>
        </div>
      </dw-menu-overlay>
    `;
  }

  doclick(e) {
    e.stopPropagation();
  }

  cancelled() {
    store.dispatch(neutralFeedback('Asignación cancelada'));
  }

  acceted() {
    window.location = this.asignData.ruta;
  }
}

customElements.define('nueva-asignacion-docente', NuevaAsignacionDocente);