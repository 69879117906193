import {LitElement, html, css} from 'lit';
/**
 * Este componente de filtro es para elementos de relaciones n a m
 */
class FilterComponentN  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
      }
    `;
  }

  static get properties() {
    return {
      elements: { type: Array },
      searchInKey: { type: String },
      returnProperty: { type: String },
    };
  }

  constructor() {
    super();
    this.elements = [];
  }

  getfilterElements(search) {
    //console.log('getfilterElements n a m', search);
    let res = this.elements.find( (item) => item[this.searchInKey] == search);
    //console.log('res', res);
    return res[this.returnProperty];
  }

  firstUpdated() {
    //console.log('elements para filter', this.elements);
  }
}

customElements.define('filter-component-n', FilterComponentN);