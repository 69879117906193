import {LitElement, html, css} from 'lit';
import { DileCheckbox } from '@dile/ui/components/checkbox/src/DileCheckbox';

class PaeCheckbox  extends DileCheckbox {

  get value() {
    return this.checked;
  }

  set value(value) {
    this.checked = value;
  }
}

customElements.define('pae-checkbox', PaeCheckbox);