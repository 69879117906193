import {LitElement, html, css} from 'lit';
import { interventionListStyles } from '../../styles/intervention-list-styles';

class InterventionListJointItem  extends LitElement {

  static get styles() {
    return [interventionListStyles, css`
      :host {
        display: block;
      }
      .detail {
        width: 32%;
      }
    `];
  }

  static get properties() {
    return {
      item: { type: Object },
      superadmin: { type: Boolean },
      hasLock: { type: Boolean },
    };
  }

  constructor() {
    super();
  }

  render() {
    return html`
      <div class="listitem">
        <span class="detail">
          ${this.item.joint_name} 
          ${this.item.joint_units > 1 
            ? html`(${this.item.joint_units}X)`
            : ''
          }
        </span>
        <span class="detail">
          ${this.item.name} 
          ${this.item.units > 1 
            ? html`(${this.item.units}X)`
            : ''
          }
        </span>
        ${this.hasLock && !this.superadmin
          ? ''
          : html`
            <dw-delete 
              icon="cancel"
              entity="joint-intervention"
              itemId="${this.item.id}"
            ></dw-delete>`
        }
      </div>
      ${this.item.comments != null
        ? html`
          <div class="listcomments">
            ${this.item.comments}
          </div>
        `
        : ''
      }
    `;
  }

  edit(e) {
    // console.log('edit!!!');
    e.preventDefault();
    this.dispatchEvent(new CustomEvent('intervention-individual-edit', {
      bubbles: true,
      composed: true,
      detail: this.item
    }));
  }
}

customElements.define('intervention-list-joint-item', InterventionListJointItem);