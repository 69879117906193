import {LitElement, html, css} from 'lit';
import './buttons/dw-button';
import '@dile/ui/components/checkbox/checkbox';

import { deleteConfirmStyles } from '../../../styles/delete-confirm-styles';

class MassDelete  extends LitElement {

  static get styles() {
    return [ deleteConfirmStyles, css`
      :host {
        display: block;
        --dw-button-background-color: var(--accent-color);
        --dw-button-text-color: #fff;
        --dw-button-icon-color: #fff;
        --dw-button-hover-background-color: red;
        --dw-button-hover-text-color: #fff;
      }
      div {
        padding: 5px 5px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid #eee;
        margin-bottom: 10px;
      }
    `];
  }

  static get properties() {
    return {
      selectAll: { type: Boolean },
      entity: { type: String },
    };
  }

  constructor() {
    super();
    this.selectAll = false;
    this.addEventListener('dile-checkbox-changed', (e) => {
      let id = e.target.dataset.checkboxid;
      console.log(e.target, id, e.detail);
      if(! e.detail) {
        console.log('marco a false');
        this.selectAll = false;
      }
    });

  }

  render() {
    return html`
      <div>
        <span>
          <dile-checkbox ?checked=${this.selectAll} @dile-checkbox-changed="${this.massChangeSelection}" data-checkboxid="0">
            ${this.selectAll ? 'Desmarcar' : 'Marcar todos'}
          </dile-checkbox>
        </span>
        <span>
          <dw-button icon="delete" @click="${this.doAsk}">Borrar elementos marcados</dw-button>
        </span>
      </div>
      <slot></slot>
      <form id="elform" action="/${this.entity}/mass-delete" method="POST">
        <input type="hidden" name="ids" id="hiddenids">
      </form>
      <dile-confirm
        cancelLabel="Cancelar"
        acceptLabel="Borrar"
        @dile-confirm-accepted="${this.doMassDelete}">
        <p>
          ¿De verdad deseas borrar estos elementos?
        </p>    
      </dile-confirm>
    `;
  }

  massChangeSelection(e) {
    e.stopPropagation();
    console.log('do mass change');
    this.selectAll = e.detail;
    this.changeSelections(this.selectAll);
  }

  changeSelections(value) {
    this.querySelectorAll('dile-checkbox').forEach(element => {
      element.checked = value;
    });
  }

  doAsk() {
    this.shadowRoot.querySelector('dile-confirm').open();
  }

  doMassDelete() {
    let checkedIds = [];
    this.querySelectorAll('dile-checkbox').forEach(element => {
      if(element.checked) {
        checkedIds.push(element.dataset.checkboxid);
      };
    });
    console.log(checkedIds);
    this.shadowRoot.getElementById('hiddenids').value=checkedIds.join('-');
    this.shadowRoot.getElementById('elform').submit();
  }
}

customElements.define('mass-delete', MassDelete);