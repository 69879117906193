import {LitElement, html, css} from 'lit';
import '@dile/ui/components/tabs/tabs';
import './intervention-form-individual';
import './intervention-form-joint';

class InterventionForm  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
        --dile-tab-selected-background-color: transparent;
        --dile-tab-background-color: transparent;
        --dile-tab-selected-text-color: var(--primary-color, blue);
        --dile-tab-selected-line-color: var(--accent-color, blue);
      }
      @media(max-width: 540px) {
        .hidemobile {
          display: none;
        }
      }
      dile-tabs {
        margin-bottom: 15px;
      }
    `;
  }

  static get properties() {
    return {
      interventionType: { type: String },
      action: { type: String },
    };
  }

  constructor() {
    super();
    this.interventionType = 'individual';
  }

  render() {
    return html`
      <dile-tabs selected="${this.interventionType}" attrForSelected="name" @dile-selected-changed="${this.typeChanged}">
        <dile-tab name="individual"><span class="hidemobile">Intervención</span> individual</dile-tab>
        <dile-tab name="conjunta"><span class="hidemobile">Intervención</span> conjunta</dile-tab>
      </dile-tabs>
      ${this.interventionType == 'individual'
        ? html`<div name="individual"><intervention-form-individual action="${this.action}"></intervention-form-individual></div>`
        : html`<div name="conjunta"><intervention-form-joint action="${this.action}"></intervention-form-joint></div>`
      }
    `;
  }

  typeChanged(e) {
    this.interventionType = e.detail.selected;
  }
}

customElements.define('intervention-form', InterventionForm);