import {LitElement, html, css} from 'lit';

class OrderLinks  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: inline-block;
      }
      div {
        opacity: 0.4;
        position: relative;
        top: 0px;
        left: 2px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      a {
        display: inline-block;
      }
      @media(min-width: 550px) {
        div {
          left: 5px;
          top: -2px;
        }
      }
    `;
  }

  static get properties() {
    return {
      uri: { type: String },
      variable: { type: String },
      queryString: { type: String },
    };
  }

  constructor() {
    super();
    this.variable = 'order';
    this.uri = './';
  }

  render() {
    return html`
      <div>
        <a href="${this.uri}?order=${this.variable}_asc${this.queryString ? `&${this.queryString}` : ''}"><img src="/images/arrow_drop_up.png"></a>
        <a href="${this.uri}?order=${this.variable}_desc${this.queryString ? `&${this.queryString}` : ''}"><img src="/images/arrow_drop_down.png"></a>
      </div>
    `;
  }
}

customElements.define('order-links', OrderLinks);