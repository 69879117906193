import {LitElement, html, css} from 'lit';
import { store } from '../../redux/store';
import { positiveFeedback, negativeFeedback } from '../../redux/actions/app_actions';

class ReportButtons  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
      }
    `;
  }

  firstUpdated() {
    this.elajax = this.shadowRoot.getElementById('elajax');
    setTimeout( () => this.createEventsInButtons(), 500);
  }

  createEventsInButtons() {
    var links = this.querySelectorAll('a');
    links.forEach(link => {
      link.addEventListener('click', e => {
        e.preventDefault();
        this.sendForm(link);
      });
    });
  }

  render() {
    return html`
      <dw-ajax
        id="elajax"
        url="/informes/create"
        method="post"
        @ajax-error="${this.ajaxError}"
        @ajax-success="${this.ajaxSuccess}"
      ></dw-ajax>
      <slot></slot>
    `;
  }

  sendForm(link) {
    this.querySelector('#informtype').value = link.dataset.informtype;
    this.querySelector('#view').value = link.dataset.view;
    let data = this.generateObjectFromForm();
    // console.log(data);
    this.elajax.data = data;
    this.elajax.generateRequest();
  }

  generateObjectFromForm() {
    let form = document.getElementById('theForm');
    let formData = new FormData(form);
    var object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    //valores que son selects múltiples
    object.individual_intervention = this.getSelectMultipleValues('selectindividual_intervention');
    object.joint_combinations = this.getSelectMultipleValues('selectjoint_combinations');
    object.situations = this.getSelectMultipleValues('selectsituations');
    return object;
  }

  getSelectMultipleValues(id) {
    let values = [];
    let data = $('#' + id).select2('data');
    // console.log(data);
    $('#'+ id).select2('data').forEach( (item) => values.push(item.id));
    return values;
  }

  ajaxError(e) {
    // console.log(e.detail);
    store.dispatch(negativeFeedback(e.detail));
  }

  ajaxSuccess(e) {
    // console.log(e.detail);
    store.dispatch(positiveFeedback(e.detail));
  }
}

customElements.define('report-buttons', ReportButtons);