import {LitElement, html, css} from 'lit';
import './dw-user-notification-item';

class DwUserNotificationList  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
        font-size: 14px;
        margin-bottom: 10px;
      }
      section {
        max-height: 250px;
        overflow-y: auto;
      }
      .delete {
        margin-top: 10px;
        padding: 5px;
        text-align: center;
        text-transform: uppercase;
        background-color: var(--primary-color);
        border-radius: 6px;
        color: #fff;
        font-size: 0.85em;
        cursor: pointer;
      }

      .no-notifications {
        margin: 5px 0;
        text-align: center;
      }
    `;
  }

  static get properties() {
    return {
      notifications: { type: Array },
    };
  }

  render() {
    //console.log(this.notifications);
    return html`
      ${this.notifications.length 
        ? html`<section>
                ${this.notifications.map( notification => html`<dw-user-notification-item .data="${JSON.parse(notification.data)}" readAt="${notification.read_at ? notification.read_at : ''}"></dw-user-notification-item>`) }
                <div class="delete" @click="${this.deleteNotifications}">Borrar notificaciones</div>
              </section>`
        : html`<div class="no-notifications">No tienes notificaciones</div>`
      }

    `;
  }

  deleteNotifications() {
    this.dispatchEvent(new CustomEvent('delete-notifications', {
      bubbles: true,
      composed: true,
    }));
  }
}

customElements.define('dw-user-notification-list', DwUserNotificationList);