import {LitElement, html, css} from 'lit';

class ReportSelectIntervalDates  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
        margin-bottom: 1.2rem;
      }
      .box {
        border: 1px solid #eee;
        background-color: #f5f5f5;
        padding: 1rem;
        border-radius: 0.4rem;
      }
      section {
        display: none;
      }
      section.active {
        display: block;
        margin-top: 1rem;
      }
    `;
  }

  static get properties() {
    return {
      active: { type: Boolean }
    };
  }

  constructor() {
    super();
    this.active = false;
  }

  render() {
    return html`
      <div class="box">
        <dile-checkbox @dile-checkbox-changed="${this.changed}">Establecer intervalo de fechas personalizado</dile-checkbox>
        <section class="${ this.active ? '' : 'active' }">
          <slot name="escolar"></slot>
        </section>
        <section class="${ this.active ? 'active' : '' }">
          <slot name="intervalform"></slot>
        </section>
      </div>
    `;
  }

  changed(e) {
    this.active = e.detail;
    this.querySelector('#setinterval').checked = e.detail;
  }
}

customElements.define('report-select-interval-dates', ReportSelectIntervalDates);