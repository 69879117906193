import {LitElement, html, css} from 'lit';
import './intervention-input-joint';
import './intervention-input-joint-second';
import { formsStyles } from '../../styles/forms-styles';
import '@dile/ui/components/textarea/textarea';

class InterventionFormJoint  extends LitElement {

  static get styles() {
    return [formsStyles, css`
      :host {
        display: block;
      }
      .jointElement {
        margin-top: 10px;
      }
      section {
        margin: 0.5rem 0;
      }
      .cancelbutton {
        --dw-button-background-color: transparent;
      }
    `];
  }

  static get properties() {
    return {
      action: { type: String }
    };
  }

  constructor() {
    super();
  }

  firstUpdated() {
    this.inputElement = this.shadowRoot.getElementById('inputElement');
    this.inputElementJoint = this.shadowRoot.getElementById('inputElementJoint');
    
  }

  render() {
    return html`
      <div>
        <section>
          <intervention-input-joint showUnits id="inputElement" label="" ></intervention-input-joint>
        </section>
        <section>
          <dile-textarea label="Comentarios" id="textarea"></dile-textarea>
        </section>
        <article class="actions">
          <rounded-button @click="${this.doAction}">${this.action}</rounded-button>
          <dw-button class="cancelbutton" @click="${this.cancel}">Cancelar</dw-button>
        </article>
      </div>
    `;
  }

  cancel() {
    this.dispatchEvent(new CustomEvent('intervention-form-cancel', {
      bubbles: true,
      composed: true,
    }));
  }

  doAction() {
    let input = this.inputElement.getInput();
    input.comments = this.shadowRoot.getElementById('textarea').value;

    this.dispatchEvent(new CustomEvent('intervention-form-joint-action', {
      bubbles: true,
      composed: true,
      detail: {
        action: this.action,
        input,
      }
    }));
  }
}

customElements.define('intervention-form-joint', InterventionFormJoint);