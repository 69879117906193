import { css } from 'lit';

export const deleteConfirmStyles = css`
  dile-confirm {
      --dile-confirm-cancel-button-color: transparent;
      --dile-confirm-text-transform: none;
      --dile-confirm-cancel-text-button-color: #666;
      --dile-confirm-accept-button-color: #e3342f;
      --dile-modal-content-shadow-blur: 6px;
      --dile-modal-content-shadow-color: rgba(0,0,0,0.5);
      --dile-modal-content-shadow-displacement: 2px;
    }
`;
