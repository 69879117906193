import {LitElement, html, css} from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { connect } from 'pwa-helpers/connect-mixin.js';
import { store } from '../../redux/store.js';

import "@dile/ui/components/modal/modal";

class ModalFeedback  extends connect(store)(LitElement) {

  static get styles() {
    return css`
      :host {
        display: block;
        --dile-modal-width: 90vw; 
        --dile-modal-max-height: 90vh;
        --dile-modal-border-radius: 4px;
        --dile-modal-content-shadow-displacement: 2px;
        --dile-modal-content-shadow-blur: 6px; 
      }
      li {
        margin-bottom: 0.5rem;
      }
    `;
  }

  static get properties() {
    return {
      opened: { type: Boolean },
      message: { type: String },
      incomingMsg: { type: String },
    };
  }

  constructor() {
    super();
    this.opened = false;
    this.message = '';
  }

  firstUpdated() {
    this.elmodal = this.shadowRoot.getElementById('elmodal')
  }

  stateChanged(state) {
    this.incomingMsg = state.app.modalFeedback;
    //console.log(state);
  }

  updated(changedProperties) {
    if (changedProperties.has('incomingMsg')) {
      this.changeIncomingMsg(this.incomingMsg);
    }
  }

  changeIncomingMsg(feedbackMsg) {
    //console.log('change incom mesg');
    if (feedbackMsg && feedbackMsg.msg) {
      this.message = feedbackMsg.msg;
      this.opened = true;
    }
  }

  render() {
    return html`
      <dile-modal showCloseIcon id="elmodal" ?opened="${this.opened}" @dile-modal-closed="${this.toBeClosed}">
        ${unsafeHTML(this.message)}
      </dile-modal>
    `;
  }

  open() {
    this.opened = true;
  }

  toBeClosed() {
    this.opened = false;
  }

  exit() {
    this.opened = false;
  }
}

customElements.define('modal-feedback', ModalFeedback);