import {LitElement, html, css} from 'lit';
import '@dile/ui/components/checkbox/checkbox';

class InputCheckbox  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
      }
      div {
        display: flex;
        align-items: flex-start;
      }
      dile-checkbox {
        margin-right: 10px;
        margin-top: 2px;
      }
      a, a:visited {
        color: var(--primary-color);
      }
      span {
        position: relative;
        top: 3px;
      }
    `;
  }

  static get properties() {
    return {
      label: { type: String },
      name: { type: String },
      checked: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.checked = false;
  }

  firstUpdated() {
    this.elcheck = this.shadowRoot.getElementById('elcheck');
    this.input = document.createElement('input');
    this.input.setAttribute('type', 'checkbox');
    this.input.setAttribute('name', this.name);
    this.input.checked = this.checked;
    this.appendChild(this.input);
  }

  render() {
    return html`
      <div>
        <dile-checkbox @click="${this.clickHandler}" id="elcheck" .checked="${this.checked}"></dile-checkbox>
        <span @click="${this.clickLabel}">${this.label}</span>
      </div>
    `;
  }
  clickHandler() {
    //console.log(this.elcheck.checked);
    this.input.checked = this.elcheck.checked;
  }
  clickLabel() {
    console.log(this.checked);
    this.checked = !this.checked;
    this.clickHandler();
  }
}

customElements.define('input-checkbox', InputCheckbox);