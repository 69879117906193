import {
  LOG_USER,
  DELETE_NOTIFICATIONS,
  ADD_NOTIFICATION,
} from '../actions/user_actions';

const INITIAL_STATE = {
  loggedIn: false,
  userData: {
    id: 0,
    email: '',
    name: '',
    notifications: [],
    unreadNotifications: 0
  }
}

export const user = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOG_USER:
      return {
        ...state,
        loggedIn: true,
        userData: {
          ...action.user
        }
      };
    case DELETE_NOTIFICATIONS:
      return {
        ...state,
        userData: {
          ...state.userData,
          notifications: [],
          unreadNotifications: 0,
        }
      }
    case ADD_NOTIFICATION: 
      return {
        ...state,
        userData: {
          ...state.userData,
          notifications: [{
            data: JSON.stringify(action.notification),
            created_at: null,
            read_at: null
          }, ...state.userData.notifications],
          unreadNotifications: state.userData.unreadNotifications + 1
        }
      }
    default:
      return state;
  }
}