import {LitElement, html, css} from 'lit';

class FilterComponent  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
      }
    `;
  }

  static get properties() {
    return {
      elements: { type: Array },
      searchInKey: { type: String },
    };
  }

  constructor() {
    super();
    this.elements = [];
  }

  getfilterElements(search) {
    return this.elements.filter( (item) => item[this.searchInKey] == search);
  }
}

customElements.define('filter-component', FilterComponent);