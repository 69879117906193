import {LitElement, html, css} from 'lit';
import {InterventionInput} from './intervention-input';
import './intervention-joint-values-form'
export class InterventionInputJoint  extends InterventionInput {

  constructor() {
    super();
  }

  firstUpdated() {
    this.interventionValuesElement = this.shadowRoot.querySelector('intervention-joint-values-form')
  }
  
  render() {
    return html`
      <intervention-joint-values-form></intervention-joint-values-form>

      ${this.unitsTemplate}
    `;
  }

  getInput() {
    return {
      intervention: this.interventionValuesElement.getData(),
      units: this.units,
    }
  }

}

customElements.define('intervention-input-joint', InterventionInputJoint);