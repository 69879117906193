import {LitElement, html, css} from 'lit';
import '../interface/time-select';

class LockForm  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
        margin-bottom: 20px;
      }
      .actions {
        text-align: right;
        padding-right: 1em;
      }
    `;
  }

  static get properties() {
    return {
      month: { type: String },
      year: { type: String },
      locks: { type: Array },
    };
  }

  constructor() {
    super();
    let date = new Date();
    this.month = date.getMonth() + 1;
    this.year = date.getFullYear();
    this.locks = [];
  }

  render() {
    return html`
      <time-select 
        .year="${this.year}" 
        .month="${this.month}" 
        @month-changed="${this.monthChanged}"
        @year-changed="${this.yearChanged}"  
      ></time-select>
      <div class="actions">
        <rounded-button @click="${this.doLock}">
          Bloquear este mes
        </rounded-button>
      </div>
    `;
  }

  monthChanged(e) {
    this.month = e.detail.value;
  }

  yearChanged(e) {
    this.year = e.detail.value;
  }

  doLock() {
    this.dispatchEvent(new CustomEvent('do-lock', {
      bubbles: true,
      composed: true,
      detail: {
        month: this.month,
        year: this.year,
      }
    }));
  }
}

customElements.define('lock-form', LockForm);