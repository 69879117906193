import {LitElement, html, css} from 'lit';
import './lock-display-month';

class LockDisplayYear  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
        margin: 10px 0 20px;
        --dw-icon-size: 20px;
      }
      h1 {
        margin: 0;
        font-size: 1.4em;
      }
      lock-display-month {
        border-bottom: 1px solid #f3f3f3;
      }
      lock-display-month:nth-child(even) {
        background-color: #f5f5f5;
      }
    `;
  }

  static get properties() {
    return {
      year: { type: Object }
    };
  }

  constructor() {
    super();
  }

  render() {
    return html`
      <dw-slide>
        <h1  slot="title">${this.year.year}</h1>
        <div slot="content">
          ${this.year.months.map( (month) => html`<lock-display-month year="${this.year.year}" month="${month}"></lock-display-month>`)}
        </div>
      </dw-slide>
    `;
  }
}

customElements.define('lock-display-year', LockDisplayYear);