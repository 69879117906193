
// NOMBRES DE ACCIONES
export const SEND_FEEDBACK = 'SEND_FEEDBACK';
export const SEND__MODAL_FEEDBACK = 'SEND__MODAL_FEEDBACK';
export const CHANGE_LOADING = 'CHANGE_LOADING';

export const sendModalFeedback = (message) => {
  return {
    type: SEND__MODAL_FEEDBACK,
    feedback: {
      msg: message
    }
  }
}

export const sendFeedback = (feedback) => {
  return {
    type: SEND_FEEDBACK,
    feedback
  }
}

export const positiveFeedback = (msg) => {
  return sendFeedback({
    msg,
    status: 'success'
  })
}
export const negativeFeedback = (msg) => {
  //console.log('negative Feedbak in app_actions')
  return sendFeedback({
    msg,
    status: 'error'
  })
}
export const neutralFeedback = (msg) => {
  return sendFeedback({
    msg,
    status: 'neutral'
  })
}

export const startLoading = () => {
  return changeLoading(true);
}

export const stopLoading = () => {
  return changeLoading(false);
}

export const changeLoading = (loading) => {
  return {
    type: CHANGE_LOADING,
    loading
  }
}


