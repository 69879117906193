import {LitElement, html, css} from 'lit';

class PaeMenu  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
        margin-bottom: 1.5rem;
      }
      .desktop {
        display: none;
      }
      @media(min-width: 670px) {
        .desktop {
          display: block;
        }
        .mobile {
          display: none;
        }
      }


    `;
  }

  static get properties() {
    return {};
  }

  constructor() {
    super();
  }

  render() {
    return html`
      <dw-action-controller class="desktop">
        <slot name="desktop"></slot>
      </dw-action-controller>
      <menu-responsive class="mobile">
        <slot name="mobile"></slot>
      </menu-responsive>
    `;
  }
}

customElements.define('pae-menu', PaeMenu);