import {LitElement, html, css} from 'lit';

class DownloadUserJob  extends LitElement {

  static get styles() {
    return [ css`
      :host {
        display: inline-block;
        cursor: pointer;
        --dw-icon-color: var(--primary-color);
      }
      dile-confirm {
        --dile-confirm-cancel-button-color: var(--primary-color);
        --dile-confirm-text-transform: none;
        --dile-confirm-cancel-text-button-color: #fff;
        --dile-confirm-accept-button-color: #e3342f;
        --dile-modal-content-shadow-blur: 6px;
        --dile-modal-content-shadow-color: rgba(0,0,0,0.5);
        --dile-modal-content-shadow-displacement: 2px;
        --dile-modal-min-width: 300px;
      }
    `];
  }

  static get properties() {
    return {
      jobId: { type: Number },
      isDeleted: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.isDeleted = false;
  }

  render() {
    return html`
      ${this.isDeleted 
        ? ''
        : html`
          <dw-icon icon="cloud_download" @click="${this.askForDelete}"></dw-icon>
          <slot></slot>
        `
      }
      
      <dile-confirm 
        cancelLabel="Solo descargar"
        acceptLabel="Con borrado"
        @dile-confirm-accepted="${this.download}"
        @dile-confirm-cancelled="${this.onlyDownload}"
        >
      <p>
        ¿Deseas borrar el archivo<br> una vez descargado?
      </p>    
      </dile-confirm>
    `;
  }

  askForDelete() {
    this.shadowRoot.querySelector('dile-confirm').open();
  }
  download() {
    this.isDeleted = true;
    window.location = `/archivos/${this.jobId}/download/delete`;
  }

  onlyDownload() {
    window.location = `/archivos/${this.jobId}/download/keep`;
  }
}

customElements.define('download-user-job', DownloadUserJob);