import {LitElement, html, css} from 'lit';

class DwStatsSquareValue  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: flex;
        align-items: stretch;
      }
      div {
        flex-grow: 1;
        background-color: var(--dw-stats-background-color, rgb(208, 239, 250));
        color: var(--dw-stats-text-color, #303030);
        padding: 15px;
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      b {
        display: block;
        text-align: center;
        font-size: var(--dw-stats-label-font-size, 0.9em);
        font-weight: var(--dw-stats-label-font-weight, 300);
        margin-bottom: 10px;
        min-height: 2em;
      }
      span {
        font-size: var(--dw-stats-value-font-size, 1.8em);
      }
    `;
  }

  static get properties() {
    return {
      label: { type: String },
      value: { type: String },
    };
  }

  render() {
    return html`
      <div>
        <b>${ this.label }</b>
        <span>${ this.value }</span>
      </div>
    `;
  }
}

customElements.define('dw-stats-square-value', DwStatsSquareValue);