import {LitElement, html, css} from 'lit';

class DwUserNotificationItem  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
        padding: 8px 10px;
        background-color: #f5f5f5;
        margin-bottom: 5px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 100%;
        overflow: hidden;
      }
      .icon {
        margin-right: 12px;
      }
      .content {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex-grow: 1;
      }
      .subject, .link {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .subject {
        font-weight: bold;
        margin-bottom: 5px;
      }
      .notReaded {
        --dw-icon-color: #c33;
      }
    `;
  }

  static get properties() {
    return {
      data: { type: Object },
      readAt: { type: String },
    };
  }

  render() {
    return html`
      <div class="icon ${this.readAt ? '' : 'notReaded'}">
        <dw-icon icon="${this.data.icon ? this.data.icon : 'notifications'}"></dw-icon>
      </div>
      <div class="content">
        <div class="subject">${this.data.subject}</div>
        <div class="link">
          <a href="${this.data.link}">${this.data.textLink}</a>
        </div>
      </div>
    `;
  }
}

customElements.define('dw-user-notification-item', DwUserNotificationItem);