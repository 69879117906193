import {LitElement, html, css} from 'lit';
import '@dile/ui/components/confirm/confirm';

import { deleteConfirmStyles } from '../../../styles/delete-confirm-styles';

class DwDelete  extends LitElement {

  static get styles() {
    return [deleteConfirmStyles, css`
      :host {
        display: inline-block;
        cursor: pointer;
        white-space: normal;
        text-align: left;
      }
      p {
        margin: 0 0 20px 0;
      }
    `];
  }

  static get properties() {
    return {
      entity: { type: String },
      itemId: { type: String },
      icon: { type: String },
    };
  }

  constructor() {
    super();
    this.icon = 'delete'  
  }

  render() {
    return html`
      <dw-icon icon="${this.icon}" @click="${this.askConfirmation}"></dw-icon>
      <dile-confirm 
        cancelLabel="Cancelar"
        acceptLabel="Borrar"
        @dile-confirm-accepted="${this.delete}">
      <p>
        ¿De verdad deseas borrar este elemento?
      </p>    
      </dile-confirm>
    `;
  }

  askConfirmation() {
    this.shadowRoot.querySelector('dile-confirm').open();
  }
  delete() {
    window.location = `/${this.entity}/${this.itemId}/delete`;
  }
}

customElements.define('dw-delete', DwDelete);