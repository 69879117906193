import {LitElement, html, css} from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import "@dile/ui/components/modal/modal";

class SigsaPopup  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
        --dile-modal-width: 90%;
      }
    `;
  }

  static get properties() {
    return {
      opened: { type: Boolean },
      message: { type: String },
    };
  }

  constructor() {
    super();
    this.message = '';
    document.addEventListener('open-sigsa-utils', this.open.bind(this));
  }

  firstUpdated() {
    this.elmodal = this.shadowRoot.getElementById('elmodal')
    this.elajax = this.shadowRoot.getElementById('elajax')
  }

  render() {
    return html`
      <dw-ajax
        id="elajax"
        url="/sigsa-utils-popup/"
        method="post"
        @ajax-error="${this.ajaxError}"
        @ajax-success="${this.ajaxSuccess}"
      ></dw-ajax>
      <dile-modal
        id="elmodal"
        ?opened="${this.opened}"
        @dile-modal-closed="${this.toBeClosed}"
      >${unsafeHTML(this.message)}</dile-modal>
    `;
  }

  open(e) {
    console.log('Abrir', e.detail);
    this.elajax.data = e.detail;
    this.elajax.generateRequest();
    this.opened = true;
    this.message = "<p>Cargando...</p>";
  }

  toBeClosed() {
    this.opened = false;
  }

  ajaxSuccess(e) {
    console.log('ajaxsuccess', e.detail);
    this.message = e.detail;
  }

  ajaxError(e) {
    console.log('ajaxerror', e.detail);
    this.message = e.detail;
  }
}

customElements.define('sigsa-popup', SigsaPopup);