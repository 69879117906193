require('select2/dist/js/select2.full');
require('select2/dist/js/i18n/es');

$(() => {
  $('.selectCommon').select2({
    containerCss: {
      'line-height': '2rem',
      'border-color': '#38c172'
    },
    dropdownCss: {
      'border-color': '#38c172'
    },
    width: '100%', 
  });
});
$(() => {
  $('.selectMultiple').select2({
    containerCss: {
      'line-height': '1.5rem',
      'border-color': '#38c172'
    },
    dropdownCss: {
      'border-color': '#38c172'
    },
    width: '100%', 
  });
});



$(() => {
  
  var studentForm = $('#studentform');
  if(studentForm.length) {

    // select dependiente para curso
    let courseElement = $('#selectcourse');
    $('#selectlevel').on('change', (e) => {
      updateValues(courseElement, 'coursesfilter', e.target.value);
    })  
    
    // select dependiente para el nivel
    let levelElement = $('#selectlevel');
    $('#selecttype').on('change', (e) => {
      updateValues(levelElement, 'levelsfilter', e.target.value);
    })
    
    // configuración de los selects dependientes después de la carga de la página
    var isCreate = studentForm.data('create');
    if(isCreate) {
      // para formularios de creación
      updateValues(levelElement, 'levelsfilter', 1);
      updateValues(courseElement, 'coursesfilter', 1);
    } else {
      // para formularios de edición
      var currentType = $('#selecttype').val();
      var currentLevel = $('#selectlevel').val();
      var currentCourse = $('#selectcourse').val();
      updateValues(levelElement, 'levelsfilter', currentType, currentLevel);
      updateValues(courseElement, 'coursesfilter', currentLevel, currentCourse);
    }
  
  }
  
  
})

// esta función actualiza los valores de un select
// - select = objeto jQuery del campo select2 a actualizar
// - filterlementid es el identificador del elemento de filtrado
// - originvalue es el valor que se tiene en el campo select de origen 
// - currentVal es el valor que debe aparecer en el select, una vez cargadas las opciones correspondientes
function updateValues(select, filterElementid, originValue, currentVal = null) {
  select.empty();
  let res = document.getElementById(filterElementid).getfilterElements(originValue);
  res.forEach( (item) => {
    var newOption = new Option(item.name, item.id, false, false);
    select.append(newOption).trigger('change');
  });
  if(currentVal) {
    select.val(currentVal);
  }
}