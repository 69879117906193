require('jquery-datetimepicker/build/jquery.datetimepicker.full');

$( () => {
  jQuery.datetimepicker.setLocale('es');
  
  $('.inputDate').datetimepicker({
    timepicker: false,
    format:'d/m/Y',
    dayOfWeekStart: 1,
    scrollMonth: false,
    scrollInput: false,
  });
})