import {LitElement, html, css} from 'lit';

class ChangeDateAssignment  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: inline;
      }
      dw-icon {
        cursor: pointer;
        position: relative;
        top: 4px;
        left: 10px;
        --dw-icon-size: 20px;
        --dw-icon-color: var(--primary-color);
      }
      h1 {
        margin: 0 0 1rem;
        font-size: 1.1rem;
        font-weight: bold;
      }
    `;
  }

  static get properties() {
    return {

    };
  }

  constructor() {
    super();
  }

  render() {
    return html`
      <dw-icon icon="edit" @click="${this.open}"></dw-icon>
      <dile-modal id="elmodal">
          <h1>Cambiar la fecha de inicio</h1>
          <slot></slot>
      </dile-modal>      
    `;
  }

  open() {
    this.shadowRoot.getElementById('elmodal').open();
  }
}

customElements.define('change-date-assignment', ChangeDateAssignment);