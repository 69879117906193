import {LitElement, html, css} from 'lit';
import '@dile/ui/components/select/select';
import './intervention-values-form';

export class InterventionInput  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
        --dile-select-width: 100%;
      }
      @media(min-width: 420px) {
        :host {
          
          --dile-select-width: 45%;
        }
        dile-select.selectValue {
          margin-right: 10px;
        }
      }
    `;
  }

  static get properties() {
    return {
      intervention: { type: String },
      label: { type: String },
      units: { type: Number },
      showUnits: { type: Boolean },
      interventionUnits: { type: Array },
    };
  }

  constructor() {
    super();
    this.intervention = '1.1';
    this.units = 1;
    this.label = '';
    this.showUnits = false;
    this.interventionUnits = [];
    for(let i = 1; i<=99; i++) {
      this.interventionUnits.push(i);
    }
  }

  render() {
    return html`
      <div>
        <intervention-values-form
          id="interventionValues"
        ></intervention-values-form>
      </div>

      

      ${this.unitsTemplate}
    `;
  }

  get unitsTemplate() {
    return html`
      ${this.showUnits
        ? html`
          <dile-select  value="${this.units}" label="Unidades ${this.label}" @element-changed="${this.selectUnitsChanged}">
            <select slot="select">
              ${this.interventionUnits.map(unit => html`<option value="${unit}">${unit}</option>`)}
            </select>
          </dile-select>
          `
        : ''
      }
    `
  }
  getInput() {
    let interventions = this.shadowRoot.getElementById('interventionValues').getData();
    console.log(interventions);
    return {
      intervention: interventions,
      units: this.units,
    }
  }

  selectUnitsChanged(e) {
    this.units = e.detail.value;
  }
}

customElements.define('intervention-input', InterventionInput);