import {LitElement, html, css} from 'lit';
import { DileForm } from '@dile/ui/mixins/form/'
import '../interface/forms/pae-checkbox';

class InterventionJointValuesForm  extends DileForm(LitElement) {

  static get styles() {
    return css`
      :host {
        display: block;
        margin-bottom: 1.5rem;
      }
      .grid {
        display: grid;
        gap: 0.75rem;
        grid-template-columns: 1fr 1fr;
      }
      .cell {
        display: flex; 
        flex-direction: column;
        gap: 0.25rem;
        border: 1px solid #eee;
        background-color: #f5f5f5;
        padding: 0.5rem;
      }
      @media(min-width: 440px) {
        .grid {
          grid-template-columns: 1fr 1fr 1fr;
        }
      }
      @media(min-width: 600px) {
        .grid {
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }
      }
      @media(min-width: 1200px) {
        .grid {
          grid-template-columns: repeat(8, 1fr)
        }
      }
    `;
  }

  static get properties() {
    return {
      intervention: { type: Array },
      interventionJoint: { type: Array }
    };
  }

  constructor() {
    super();
    this.intervention = ['3.1', '3.2', '3.3', '3.4', '3.5', '3.6', '3.7', '3.8'];
    this.interventionJoint = ['2.1', '2.2', '2.3', '2.4', '2.5', '2.6'];
  }

  render() {
    return html`
      <div class="grid">
        ${this.intervention.map( intervention => html`
          <div class="cell">
          ${this.interventionJoint.map( interventionJoint => html`
            <pae-checkbox name="${intervention}|${interventionJoint}">(${intervention} - ${interventionJoint})</pae-checkbox>
          `)}
          </div>  
        `)}
      </div>
    `;
  }
}

customElements.define('intervention-joint-values-form', InterventionJointValuesForm);