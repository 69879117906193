import {LitElement, html, css} from 'lit';
import { DileForm } from '@dile/ui/mixins/form/'
import '../interface/forms/pae-checkbox';

class InterventionValuesForm  extends DileForm(LitElement) {

  static get styles() {
    return css`
      :host {
        display: block;
        margin-bottom: 1.5rem;
      }
      .grid {
        display: grid;
        gap: 0.75rem;
        grid-template-columns: 1fr 1fr;
      }
      .cell {
        display: flex; 
        flex-direction: column;
        gap: 0.25rem;
        border: 1px solid #eee;
        background-color: #f5f5f5;
        padding: 0.5rem;
      }
      @media(min-width: 440px) {
        .grid {
          grid-template-columns: 1fr 1fr 1fr;
        }
      }
      @media(min-width: 600px) {
        .grid {
          grid-template-columns: 1fr 1fr 1fr 1fr;
        }
      }
      @media(min-width: 1200px) {
        .grid {
          grid-template-columns: repeat(8, 1fr)
        }
      }
    `;
  }

  static get properties() {
    return {
      
    };
  }

  constructor() {
    super();
  }

  render() {
    return html`
      <div class="grid">
        <div class="cell">
          <pae-checkbox name="1.1">1.1</pae-checkbox>
          <pae-checkbox name="1.2">1.2</pae-checkbox>
          <pae-checkbox name="1.3">1.3</pae-checkbox>
        </div>
        <div class="cell">
          <pae-checkbox name="2.1">2.1</pae-checkbox>
          <pae-checkbox name="2.2">2.2</pae-checkbox>
          <pae-checkbox name="2.3">2.3</pae-checkbox>
          <pae-checkbox name="2.4">2.4</pae-checkbox>
          <pae-checkbox name="2.5">2.5</pae-checkbox>
          <pae-checkbox name="2.6">2.6</pae-checkbox>
        </div>
        <div class="cell">
          <pae-checkbox name="4.1">4.1</pae-checkbox>
          <pae-checkbox name="4.2">4.2</pae-checkbox>
          <pae-checkbox name="4.3">4.3</pae-checkbox>
        </div>
        <div class="cell">
          <pae-checkbox name="5.1">5.1</pae-checkbox>
          <pae-checkbox name="5.2">5.2</pae-checkbox>
          <pae-checkbox name="5.3">5.3</pae-checkbox>
          <pae-checkbox name="5.4">5.4</pae-checkbox>
        </div>
        <div class="cell">
          <pae-checkbox name="6.1">6.1</pae-checkbox>
          <pae-checkbox name="6.2">6.2</pae-checkbox>
          <pae-checkbox name="6.3">6.3</pae-checkbox>
          <pae-checkbox name="6.4">6.4</pae-checkbox>
          <pae-checkbox name="6.5">6.5</pae-checkbox>
        </div>
        <div class="cell">
          <pae-checkbox name="7.1">7.1</pae-checkbox>
          <pae-checkbox name="7.2">7.2</pae-checkbox>
          <pae-checkbox name="7.2.1">7.2.1</pae-checkbox>
          <pae-checkbox name="7.2.2">7.2.2</pae-checkbox>
          <pae-checkbox name="7.2.3">7.2.3</pae-checkbox>
          <pae-checkbox name="7.2.4">7.2.4</pae-checkbox>
          <pae-checkbox name="7.2.5">7.2.5</pae-checkbox>
          <pae-checkbox name="7.2.6">7.2.6</pae-checkbox>
          <pae-checkbox name="7.3">7.3</pae-checkbox>
          <pae-checkbox name="7.4">7.4</pae-checkbox>
          <pae-checkbox name="7.5">7.5</pae-checkbox>
          <pae-checkbox name="7.6">7.6</pae-checkbox>
          <pae-checkbox name="7.7">7.7</pae-checkbox>
        </div>
        <div class="cell">
          <pae-checkbox name="8.1">8.1</pae-checkbox>
          <pae-checkbox name="8.2">8.2</pae-checkbox>
          <pae-checkbox name="8.3">8.3</pae-checkbox>
          <pae-checkbox name="8.4">8.4</pae-checkbox>
        </div>
        <div class="cell">
          <pae-checkbox name="9.0">9.0</pae-checkbox>
          <pae-checkbox name="9.1">9.1</pae-checkbox>
          <pae-checkbox name="9.2">9.2</pae-checkbox>
        </div>
      </div>
    `;
  }
}

customElements.define('intervention-values-form', InterventionValuesForm);