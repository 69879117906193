import {LitElement, html, css} from 'lit';
import '../interface/dw-menu-overlay';
import { userOptionsStyle } from '../../styles/login-menu-styles';
import './dw-user-notification-list';
import { connect } from 'pwa-helpers';
import { store } from '../../redux/store';

import {
  logUser,
  getUser,
  checkNotificationsAsRead,
  deleteNotifications,
} from '../../redux/actions/user_actions.js';

class DwUserMenu extends connect(store)(LitElement) {
  static get properties() {
    return {
      loggedIn: { type: Boolean },
      userData: { type: String },
      firstOpened: { type: Boolean },
    }
  }

  static get styles() {
    return [userOptionsStyle, css`
    :host {
      display: flex;
      align-items: center;
      
    }

    dw-menu-overlay {
      --dw-menu-overlay-width: 300px;
    }
    
    .menuOverlay {
      padding: 15px 5px 10px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    dw-button.notificated::after {
      background-color: #d44;
      border-radius: 20px;
      border: 2px solid #ffffff;
      box-sizing: content-box;
      color: #ffffff;
      content: attr(data-number);
      display: block;
      font-size: 12px;
      line-height: 13px;
      min-width: 13px;
      padding: 3px;
      position: absolute;
      right: 0;
      text-align: center;
      top: -8px;
      right: -8px;
      transform: scale(0.8);
    }
    .actions {
      border-top: 2px solid #eee;
      text-align: center;
      display: flex;
      align-items: center;
      padding-top: 10px;
      justify-content: center;
    }
    `];
  }

  constructor() {
    super();
    this.loggedIn = false;
  }

  firstUpdated() {
    store.dispatch(getUser());
    this.firstOpened = false;
  }

  stateChanged(state) {
    this.loggedIn = state.user.loggedIn;
    this.userData = state.user.userData;
  }

  render() {
    return html`
      ${this.loggedIn 
        ? html`
          <dw-menu-overlay 
            movetop="-4" 
            moveleft="5" 
            id="overlay" 
            @click="${(e) => e.stopPropagation()}"
            @overlay-opened="${this.manageOpen}"
            >
            <div slot="trigger">
              <dw-button 
                icon="arrow_drop_down" 
                class="${this.userData.unreadNotifications > 0 ? 'notificated' : ''}"
                data-number="${this.userData.unreadNotifications}"
              ><slot></slot></dw-button>
            </div>
            <article slot="content" class="menuOverlay">
              <dw-user-notification-list 
                .notifications="${this.userData.notifications}"
                @delete-notifications="${this.deleteNotifications}"
              ></dw-user-notification-list>
              <div class="actions">
                <span><dw-icon icon="exit_to_app"></dw-icon></span>
                <a href="#" @click="${this.doLogout}">Logout</a>
              </div>
            </article>
          </dw-menu-overlay>
          `
        : ''
      }
      
    `;
  }

  doLogout(e) {
    e.preventDefault();
    this.shadowRoot.getElementById('overlay').close();
    document.getElementById('logout-form').submit();
  }

  deleteNotifications() {
    store.dispatch(deleteNotifications());
  }

  manageOpen() {
    if (!this.firstOpened) {
      this.firstOpened = true;
      store.dispatch(checkNotificationsAsRead());
    }
  }
}

customElements.define('dw-user-menu', DwUserMenu);