import {
  createStore, applyMiddleware, combineReducers
} from 'redux';

import thunk from 'redux-thunk';

import { app } from './reducers/app_reducers';
import { user } from './reducers/user_reducers';

const eitApp = combineReducers({
  app, user
});

export const store = createStore(eitApp, applyMiddleware(thunk));
