import {LitElement, html, css} from 'lit';
import '@dile/ui/components/confirm/confirm';

class DileLinkConfirm extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
      }
    `;
  }

  static get properties() {
    return {
      href: { type: String },
      confirmMsg: { type: String},
      acceptLabel: { type: String },
      cancelLabel: { type: String },
      blocking: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.acceptLabel = "Accept";
    this.cancelLabel = "Cancell";
    this.blocking = false;
  }

  firstUpdated() {
    this.elconfirm = this.shadowRoot.getElementById('elconfirm');
  }

  render() {
    return html`
      <dile-confirm
        id="elconfirm"
        acceptLabel="${this.acceptLabel}"
        cancelLabel="${this.cancelLabel}"
        ?blocking="${this.blocking}"
        @dile-confirm-accepted="${this.doNavigate}"
      >
        <div>
          <slot name="confirm-msg"></slot>
        </div>
      </dile-confirm>
      <span @click="${this.doConfirm}"><slot name="trigger"></slot></span>
    `;
  }

  doConfirm() {
    this.elconfirm.open();
  }

  doNavigate() {
    window.location = this.href;
  }
}

customElements.define('dile-link-confirm', DileLinkConfirm);

