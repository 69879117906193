import {LitElement, html, css} from 'lit';
import '../utils/dw-ajax';
import { store } from '../../redux/store';
import { positiveFeedback, negativeFeedback } from '../../redux/actions/app_actions';
import './intervention-list';
import './intervention-individual-edit';

class StudentTracing  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
      }
      .actions, student-tracing-create {
        margin-bottom: 15px;
      }
      .blocklabel {
        color: #933;
        display: inline-block;
        margin-bottom: 15px;
      }
      .lockLabel {
        font-size: 0.8em;
        margin-bottom: 1.2em;
      }
    `;
  }

  static get properties() {
    return {
      student: { type: Number},
      month: { type: Number },
      year: { type: Number },
      showCreateInterventionForm: { type: Boolean },
      interventions: { type: Object },
      hasLock: { type: Boolean },
      superadmin: { type: Boolean },
    };
  }

  constructor() {
    super();
    this.showCreateInterventionForm = false;
  }

  firstUpdated() {
    this.ajaxGetTracing = this.shadowRoot.getElementById('ajaxGetTracing');
    this.ajaxCreateIndividualIntervention = this.shadowRoot.getElementById('ajaxCreateIndividualIntervention');
    this.ajaxCreateJointIntervention = this.shadowRoot.getElementById('ajaxCreateJointIntervention');
    
    this.interventionIndividualEditElement = this.shadowRoot.querySelector('intervention-individual-edit');
    //console.log(this.interventionIndividualEdit);
    this.getCurrentTracing();

    this.addEventListener('intervention-form-cancel', () => {
      this.showCreateInterventionForm = false;
    })
  }

  render() {
    return html`
      <dw-ajax
        id="ajaxGetTracing"
        url="/api/tracing/get-interventions"
        method="post"
        @ajax-error="${this.getTracingError}"
        @ajax-success="${this.updateInterventions}"
      ></dw-ajax>

      <dw-ajax
        id="ajaxCreateIndividualIntervention"
        url="/api/tracing/create-individual-intervention"
        method="post"
        @ajax-error="${this.getTracingError}"
        @ajax-success="${this.ajaxCreateSuccess}"
      ></dw-ajax>

      <dw-ajax
        id="ajaxCreateJointIntervention"
        url="/api/tracing/create-joint-intervention"
        method="post"
        @ajax-error="${this.getTracingError}"
        @ajax-success="${this.ajaxCreateSuccess}"
      ></dw-ajax>

      ${this.showCreateInterventionForm
        ? html`
          <intervention-create
            @intervention-form-individual-action="${this.interventionDoCreate}"
            @intervention-form-joint-action="${this.interventionJointDoCreate}"
          ></intervention-create>
        `
        : html`
          ${this.hasLock && !this.superadmin
            ? html`<span class="blocklabel">Este mes está bloqueado</span>`
            : html`
              <div class="actions">
                <rounded-button @click="${this.showCreate}">Crear Intervención</rounded-button>
              </div>
              ${this.hasLock
                ? html`<span class="lockLabel">* Mes bloqueado</span>`
                : ''
              }
              `

          }
        `
      }
      
      <intervention-list 
        ?hasLock="${this.hasLock}"
        ?superadmin="${this.superadmin}"
        .interventions="${this.interventions}"
        @intervention-individual-edit="${this.interventionIndividualEdit}"
      ></intervention-list>

    `;
  }

  getTracingError(e) {
    store.dispatch(negativeFeedback(e.detail));
  }

  getCurrentTracing() {
    this.ajaxGetTracing.data = {
      student_id: this.student,
      month: this.month,
      year: this.year,
    }
    this.ajaxGetTracing.generateRequest();
  }

  showCreate() {
    this.showCreateInterventionForm = true;
  }

  interventionDoCreate(e) {
    console.log('interventionDoCreate', e.detail);
    this.ajaxCreateIndividualIntervention.data = this.createInterventionObject(e.detail.input);
    this.ajaxCreateIndividualIntervention.generateRequest();
  }

  interventionJointDoCreate(e) {
    // console.log('intervention Joiny DoCreate', e.detail);
    this.ajaxCreateJointIntervention.data = this.createInterventionObject(e.detail.input);
    this.ajaxCreateJointIntervention.generateRequest();
  }

  createInterventionObject(input) {
    return {
      student_id: this.student,
      month: this.month,
      year: this.year,
      interventionValue: input.intervention,
      interventionUnits: input.units,
      interventionComments: input.comments,
    }
  }

  ajaxCreateSuccess() {
    // console.log('ajaxCreateSuccess');
    this.showCreateInterventionForm = false;
    store.dispatch(positiveFeedback('Intervención creada con éxito'))
    this.ajaxGetTracing.generateRequest();
  }

  updateInterventions(e) {
    this.interventions = e.detail;
    // console.log( 'interventios actualizados', this.interventions);
  }

  
}

customElements.define('student-tracing', StudentTracing);