import {LitElement, html, css} from 'lit';

import '../interface/time-select';

class TracingTimeNavigator  extends LitElement {

  

  static get properties() {
    return {
      student: { type: Number },
      year: { type: Number },
      month: { type: Number },
    };
  }

  constructor() {
    super();
  }

  

  render() {
    return html`
      <time-select 
        year="${this.year}"
        month="${this.month}"
        @month-changed="${this.selectMonth}"
        @year-changed="${this.selectYear}"
      ></time-select>
    `;
  }

  selectMonth(e) {
    //console.log('selectMonth', e.detail.value, this.month);
    if(e.detail.value != this.month) {
      window.location = `/alumnos/${this.student}/seguimiento/${this.year}/${e.detail.value}`;
    }
  } 

  selectYear(e) {
    //console.log('selectMonth', e.detail.value, this.year);
    if(e.detail.value != this.year) {
      window.location = `/alumnos/${this.student}/seguimiento/${e.detail.value}/${this.month}`;
    }
  } 
}

customElements.define('tracing-time-navigator', TracingTimeNavigator);