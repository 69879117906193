import {LitElement, html, css} from 'lit';
import { store } from '../../../redux/store';
import { positiveFeedback, negativeFeedback } from '../../../redux/actions/app_actions';

class PaeLevelSelector  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
      }
      .menubutton {
        display: flex;
        align-items: center;
      }
      .content {
        padding: 0.5rem;
      }
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }
      li {
        margin: 0.25rem 0;
      }
    `;
  }

  static get properties() {
    return {
      studentTypeId: { type: String },
      studentLevels: { type: Array },
      currentLevels: { type: Array },
    };
  }

  constructor() {
    super();
    this.studentLevels = [];
    this.currentLevels = [];
  }

  firstUpdated() {
    this.changeLevelAjax = this.shadowRoot.getElementById('changeLevelAjax');
  }

  render() {
    return html`
      <dw-ajax
        id="changeLevelAjax"
        url="/tipos-centro/${this.studentTypeId}/attach-level"
        method="post"
        @ajax-success="${this.ajaxSuccess}"
        @ajax-error="${this.ajaxError}"
      ></dw-ajax>
      <dw-menu-overlay horizontalAlign="center" moveLeft="-60">
        <div class="menubutton" slot="trigger">
          <span>Niveles</span>
          <dw-icon icon="more_vert"></dw-icon>
        </div>
        <div slot="content" class="content">
          <ul>
            ${this.studentLevels.map( (level) => html`
              <li>
                <dile-checkbox 
                  ?checked=${this.hasLevel(level.id, this.currentLevels)}
                  @dile-checkbox-changed=${this.checkboxChangedHandlerCreator(level.id)}  
                >${level.name}</dile-checkbox>
              </li>
            `)}
          </ul>
        </div>
      </dw-menu-overlay>
    `;
  }

  hasLevel(levelId, currentLevels) {
    let level = currentLevels.find(level => level == levelId);
    return level ? true : false;
  }

  checkboxChangedHandlerCreator(levelId) {
    return (e) => {
      console.log(levelId, e.detail);
      this.changeLevelAjax.data = {
        levelId,
        attached: e.detail,
      }
      this.changeLevelAjax.generateRequest();
    }
  }

  ajaxError(e) {
    store.dispatch(negativeFeedback(e.detail));
  }

  ajaxSuccess(e) {
    store.dispatch(positiveFeedback(e.detail));
  }
}

customElements.define('pae-level-selector', PaeLevelSelector);