import {LitElement, html, css} from 'lit';

import { store } from '../../redux/store';
import { addNotificationRecived } from '../../redux/actions/user_actions';

class EitNotificationListener  extends LitElement {

  static get properties() {
    return {
      userId: { type: Number },
    };
  }

  firstUpdated() {
    if(this.userId) {
      window.Echo.private('App.User.' + this.userId).notification((notification) => {
        //console.log('AKI RECIBO:::::', notification);
        store.dispatch(addNotificationRecived(notification));
      });
    }
  }

}

customElements.define('eit-notification-listener', EitNotificationListener);