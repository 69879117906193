import {LitElement, html, css} from 'lit';
import { DileOverlay } from '@dile/ui/mixins/overlay';
import { DileCloseDocumentClick } from '@dile/ui/mixins/close-document-click';

class DwMenuOverlay extends DileOverlay(DileCloseDocumentClick(LitElement)) {

  static get styles() {
    return css`
      :host {
        display: block;
        position: relative;
        box-sizing: border-box;
      }
      #overlay {
        box-sizing: border-box;    
        color: var(--dw-menu-overlay-color, #303030);    
        display: none;
        z-index: var(--dile-user-config-box-zindex, 999);
        position: absolute;
        opacity: 0;
        background-color: #fff;
        border-radius: 5px;
        width: var(--dw-menu-overlay-width, 280px);    
        max-width: 300px;
        padding: 1px;
        transition: ease 0.5s;
        transition-property: transform, opacity;
        transform: translateY(-10px);
        box-shadow: 0 0 20px rgba(102, 102, 102, 0.5);
      }
      #overlay.opened {
        opacity: 1;
        transform: translateY(0);
      }
      #trigger {
        display: inline-block;
      }
    `;
  }

  render() {
    return html`
    <span @click="${this.toggle}" id="trigger"><slot name="trigger"></slot></span>
    <div id="overlay" class="${this._overlayClass}" @updated="${this.updatedMethod}">
      <slot name="content"></slot>
    </div>
    `;
  }

  updatedMethod() {
    // console.log('updatedMethod')
    setTimeout(() => this.close(), 500);
  }

  
  
}

customElements.define('dw-menu-overlay', DwMenuOverlay);