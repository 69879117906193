import {LitElement, html, css} from 'lit';
import '@dile/ui/components/modal/modal';
import { getMonthName } from '../../helpers/dates-helper';

class InterventionIndividualEdit  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
        --dile-modal-width: auto;
        --dile-modal-max-width: 95%;
      }
      
    `;
  }

  static get properties() {
    return {
      item: { type: Object },
    };
  }

  constructor() {
    super();
    this.item = {}
  }

  firstUpdated() {
    this.elmodal = this.shadowRoot.querySelector('dile-modal')
  }

  render() {
    return html`
      <dile-modal>
        <intervention-form-individual action="Editar"></intervention-form-individual>
        <p>
          Mes: ${getMonthName(this.item.month)}
        </p>
      </dile-modal>
    `;
  }

  edit(item) {
    this.item = item;
    this.elmodal.open();
  }

  close() {
    this.elmodal.close();
  }
}

customElements.define('intervention-individual-edit', InterventionIndividualEdit);