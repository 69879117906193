import {LitElement, html, css} from 'lit';
import { months } from '../../helpers/dates-helper';

class LockDisplayMonth  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
      }
      .item {
        padding: 7px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    `;
  }

  static get properties() {
    return {
      month: { type: Number },
      year: { type: Number },
    };
  }

  constructor() {
    super();
  }

  render() {
    return html`
      <div class="item">
        <span class="name">
          ${months[this.month -1]}
        </span> 
        <span class="actions">
          <dw-delete icon="cancel" entity="lock" itemid="${this.year}-${this.month}"></dw-delete>
        </span>

      </div>
    `;
  }
}

customElements.define('lock-display-month', LockDisplayMonth);