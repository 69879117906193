import {LitElement} from 'lit';
import { IconsMixin } from '../../mixins/icons-mixin';
/*
--dw-icon-size: tamaño del icono
--dw-icon-color: color del icono
*/
export class DWIcon extends IconsMixin(LitElement) {  
  constructor() {
    super();
    this.icon = 'done';
  }
}

customElements.define('dw-icon', DWIcon);
