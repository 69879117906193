import { html } from 'lit';
import {InterventionInput} from './intervention-input';
import '@dile/ui/components/select/select';

export class InterventionInputJointSecond  extends InterventionInput {

  constructor() {
    super();
    this.intervention = '2.1';
  }

  render() {
    return html`
      <dile-select class="selectValue" value="${this.intervention}" label="Intervención ${this.label}" @element-changed="${this.selectInterventionChanged}">
        <select slot="select">
          <option value="2.1">2.1</option>
          <option value="2.2">2.2</option>
          <option value="2.3">2.3</option>
          <option value="2.4">2.4</option>
          <option value="2.5">2.5</option>
          <option value="2.6">2.6</option>
        </select>
      </dile-select>

      ${this.unitsTemplate}
    `;
  }

  getInput() {
    return {
      intervention: this.intervention,
      units: this.units,
    }
  }

  selectInterventionChanged(e) {
    this.intervention = e.detail.value;
  }
}

customElements.define('intervention-input-joint-second', InterventionInputJointSecond);