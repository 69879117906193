import {LitElement, html, css} from 'lit';
import '../../redux/store';
import {positiveFeedback, negativeFeedback} from '../../redux/actions/app_actions';
import { store } from '../../redux/store';

class PreventiveProjectItemCrud  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
        margin-bottom: 0.5rem;
        border-bottom: 1px solid #ddd;
        display: flex;
        padding: 0.6rem 1rem;
        background-color: #f5f5f5;
        align-items: center;
      }
      .editicon {
        position: relative;
        top: 2px;
        margin-right: 0.5rem;
        --dw-icon-color: var(--primary-color);
      }
      .editiconcancel {
        --dw-icon-color: #d57979;
      }
      input {
        font-size: 0.9rem;
      }
      .grow {
        flex-grow: 1;
        padding-right:0.5rem;
      }
      .danger {
        --dw-icon-color: #e3342f;
      }
      .actions {
        white-space: nowrap;
      }
    `;
  }

  static get properties() {
    return {
      name: { type: String },
      projectId: { type: Number },
      editing: { type: Boolean },
    };
  }

  constructor() {
    super();
  }

  firstUpdated() {
    this.editAjax = this.shadowRoot.getElementById("editAjax");
  }

  render() {
    return html`
      <dw-ajax
        id="editAjax"
        url="/api/preventive_projects/edit_project_name"
        method="post"
        @ajax-success="${this.editSuccess}"
        @ajax-error="${this.editError}"
      ></dw-ajax>
      ${this.editing
        ? html`
          <span class="editicon editiconcancel" @click="${this.hideEdit}"><dw-icon icon="cancel"></dw-icon></span>
          <span class="editicon" @click="${this.doEdit}"><dw-icon icon="done"></dw-icon></span>
          <span class="grow"><input type="text" value="${this.name}" id="nameinput"></span>`
        : html`
          <span class="editicon" @click="${this.showEdit}"><dw-icon icon="edit"></dw-icon></span>
          <span class="grow">${this.name}</span>
          <div class="actions">
            <span class="editicon"><a href="/memoria-distrito/proyecto/${this.projectId}"><dw-icon icon="assignment"></dw-icon></a></span>
            <span class="editicon"><dw-delete entity="district_memory_prevention_projects" itemId="${this.projectId}" class="danger"></dw-delete></span>
          </div>
          `
      }
      
    `;
  }

  showEdit() {
    this.editing = true;
  }
  hideEdit() {
    this.editing = false;
  }
  doEdit() {
    this.editAjax.data = {
      name: this.shadowRoot.getElementById('nameinput').value,
      projectId: this.projectId,
    }
    this.editAjax.generateRequest();
  }

  editSuccess(response) {
    console.log(response.detail);
    store.dispatch(positiveFeedback(response.detail.msg));
    this.name = response.detail.name;
    this.editing = false;
  }
  editError(response) {
    store.dispatch(negativeFeedback(response.detail));
  }
}

customElements.define('preventive-project-item-crud', PreventiveProjectItemCrud);