import {LitElement, html, css} from 'lit';

class SigsaUtility  extends LitElement {

  static get styles() {
    return css`
      :host {
        display: block;
      }
      .placeholder {
        cursor: pointer;
      }
    `;
  }

  static get properties() {
    return {
      studentId: { type: Number },
      year: { type: Number },
      month: { type: Number },
    };
  }

  constructor() {
    super();
  }

  render() {
    return html`
      <div class="placeholder" @click="${this.open}">
        <slot></slot>
      </div>
    `;
  }

  open() {
    console.log('aki');
    this.dispatchEvent(new CustomEvent('open-sigsa-utils', {
      bubbles: true,
      composed: true,
      detail: {
        studentId: this.studentId,
        month: this.month,
        year: this.year,

      }
    }));
  }
}

customElements.define('sigsa-utility', SigsaUtility);