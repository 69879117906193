import {LitElement, html, css} from 'lit';
import { interventionListStyles } from '../../styles/intervention-list-styles';

class InterventionListIndividualItem  extends LitElement {

  static get styles() {
    return [interventionListStyles, css`
      :host {
        display: block;
      }
    `];
  }

  static get properties() {
    return {
      item: { type: Object },
      superadmin: { type: Boolean },
      hasLock: { type: Boolean },
    };
  }

  constructor() {
    super();
  }

  render() {
    return html`
      <div class="listitem">
        <span class="detail">
          ${this.item.name} 
          ${this.item.units > 1 
            ? html`(${this.item.units}X)`
            : ''
          }
        </span>
        <span>
          ${this.hasLock && !this.superadmin
            ? ''
            : html`
              <dw-delete 
                icon="cancel"
                entity="individual-intervention"
                itemId="${this.item.id}"
              ></dw-delete>
            `
          }
        </span>
      </div>
      ${this.item.comments != null 
        ? html`
          <div class="listcomments">
            ${this.item.comments}
          </div>
        `
        : ''
      }
    `;
  }

  edit(e) {
    // console.log('edit!!!');
    e.preventDefault();
    this.dispatchEvent(new CustomEvent('intervention-individual-edit', {
      bubbles: true,
      composed: true,
      detail: this.item
    }));
  }
}

customElements.define('intervention-list-individual-item', InterventionListIndividualItem);