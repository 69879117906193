import { css } from 'lit';

export const iconStyles = css`
  :host[hidden] { display: none;}
  :host { display: inline-block; line-height: 0}
  path {
    fill: var(--dw-icon-color, #888);
    transition: all 0.3s ease-in;
  }
  path[fill="none"] {
    fill: transparent;
  }
  svg {
    width: var(--dw-icon-size, 24px);
    height: var(--dw-icon-size, 24px);
    line-height: 24px;
  }
  div { display: flex;}
`;